@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}
.container{
  max-width: 80%;
  margin: auto;
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button{
  border-radius: 5px;
  padding: 17px 30px;
  background-color: #ff6600;
  color: #fff;
  font-weight: bold;
  border: none;
  margin: 10px;
  cursor: pointer;
}

button i {
  margin-right: 5px;
}

.heading {
  text-align: center;
  width:60%;
  margin: auto;
}
.heading h1{
  color: #2f3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}
.heading p {
  color: #72809d;
}
textarea, input{
  border: none;
  outline: none;
  background-color: none;
}

.background {
  padding: 80px 0;
  position: relative;
  background-color: #f7f9fc;
}
.grid5{
  display: grid;
  grid-template-columns: repeat(13,1fr);
  grid-gap:30px;
}
label{
  color:#2d3954;
  font-size: 14px;
}
.mtop{
  margin-top: 50px;
}

h4{
  font-weight: 500;
  margin-top: 10px;
}
.shadow{
  box-shadow: 0 0 120px 0 rgb(112 121 138 / 18% );
}
.padding{
  padding:80px 0;
}
.grid3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap:30px;
}
img {
  width:100%;
  height:100%;
}
.btn2{
  border-radius: 50px;
  font-size: 20px;
}
.btn5 {
  border-radius: 50px;
  padding: 6px 26px;
  color: #ff6600;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}
.back{
  height: 40vh;
  position: relative;
}
.back img{
  height: 40vh;
  position: absolute;
  top: 0;
  left:0;
  z-index: -1;
  object-fit: cover;
}
.back::after{
  content: '';
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 40vh;
  background: rgba(17,40,72, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}
.mb {
  margin-bottom: 80px;
}
.mt {
  margin-top: 80px;
}
.mr {
  margin-right: 15px;
  cursor: pointer;
}
.grid6{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap:30px;
}
.contact-icons-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}
.contact-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  text-decoration: none;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin-right: 10px;
}

.icon-label {
  color: #333;
  font-weight: bold;
  opacity: 1;
  transition: all 0.3s ease;
}

.contact-icon:hover {
  padding-right: 20px;
}
.contact-icon:hover .icon-wrapper {
  transform: rotate(15deg);
}

.phone-icon .icon-wrapper {
  background-color: #007bff; 
}

.whatsapp-icon .icon-wrapper {
  background-color: #25D366;
}

.contact-icon svg {
  width: 20px;
  height: 20px;
  color: white;
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.6);
  }
  70% {
      box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
  }
}

.whatsapp-icon .icon-wrapper,
.phone-icon .icon-wrapper { 
  animation: pulse 2s infinite;
}
@media screen and (max-width: 767px) {
  .contact-icons-container {
    bottom: 10px;
    right: 10px;
  }
  
  .contact-icon {
    padding: 8px;
  }
  
  .icon-wrapper {
    width: 40px;
    height: 40px;
  }
  
  .contact-icon svg {
    width: 20px;
    height: 20px;
  }
  
  .icon-label {
    display: none;
  }
}

@keyframes shake {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(4deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(2deg); }
  85% { transform: rotate(-2deg); }
  92% { transform: rotate(1deg); }
  100% { transform: rotate(0); }
}

.contact-icon:hover .icon-wrapper {
  animation: none;
}

@media screen and (max-width: 767px) {
    .contact-icons-container {
      bottom: 10px;
      right: 10px;
    }
    
    .contact-icon {
      padding: 8px;
    }
    
    .icon-wrapper {
      width: 30px;
      height: 30px;
    }
    
    .contact-icon svg {
      width: 15px;
      height: 15px;
    }
    
    .icon-label {
      font-size: 12px;
      background-color: #ff6600;
    }
}

.whatsapp-container {
  position: fixed;
  bottom: 33px;
  right: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: #25d366;
  color: #fff;
  border-radius: 60%;
  transition: all 0.2s ease-in-out;
}
.whatsapp_float{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-decoration: none;
    background-color: #25d366;
    box-shadow: 2px 2px 3px #999;
    padding:12px;
}

.phone_float {
    background-color: #25d366;
    border: none;
    cursor: pointer;
    width: 55px;
    height: 55px;
    padding:10px;
}

.whatsapp-icon,
.phone-icon {
    margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 55px;
    bottom: 20px;
    right: 10px;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .grid3,
  .grid4,
  .grid6 {
    grid-template-columns: repeat(1, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back,
  .back::after,
  .back img {
    height: 30vh;
  }
  .btn5 {
    border-radius: 20px;
    padding: 9px 16px;
    color: #ff6600;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid #27ae601f;
    background: #fff;
  }
  .back h1 {
    font-size: 20px;
    font-weight: 500;
  }
}

@media screen and (min-width: 992px) {
  .grid3,
  .grid4 {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid6 {
    grid-template-columns: repeat(4, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back,
  .back::after,
  .back img {
    height: 30vh;
  }
  
}

.developer-container{
  background-color: #000;
  color:#fff;
  height:30px;
  padding:5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: sticky;
}
.developer-content{
  font-size: 13px;
  font-weight: 400;
  animation: marquee 20s linear infinite alternate;
  animation-play-state: running;
}
.developer-container:hover .developer-content {
  animation-play-state: paused;
}

@media screen and (max-width: 768px) {
  .developer-container{
    background-color: #000;
    color:#fff;
    height:50px;
    width:100%;
    padding:10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: sticky;
  }
  .developer-content{
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    animation: paused; 
    animation-play-state: running; 
  }
  .developer-container:hover .developer-content {
    animation-play-state: paused;
  }
}