header {
  background-color: #fff;
  height: auto;
  min-height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 13px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header img {
  width: 160px;
}

header a {
  margin-left: 15px;
  transition: 0.5s;
  font-weight: 500;
}

header a:hover {
  color: #ff6600;
}

header .nav a.active {
  color: #ff9933;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: #ff9933;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

header .nav ul {
  display: flex;
  align-items: center;
}

header .nav li {
  margin: 0 5px;
}

@media screen and (max-width: 992px) {
  header .nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: none;
    padding: 20px 0;
    box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  }

  header .nav.active {
    display: block;
  }

  header .nav ul.mobile-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  header .nav ul.mobile-nav li {
    margin: 10px 0;
    width: 100%;
  }

  header .nav ul.mobile-nav a {
    display: block;
    padding: 10px 20px;
  }

  header .button {
    display: none;
  }

  header .toggle {
    display: block;
  }

  header .toggle button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #ff9933;
  }
}

@media screen and (max-width: 576px) {
  header .container {
    padding: 0 15px;
  }

  header img {
    width: 120px;
  }

  header .nav ul.mobile-nav a.active {
    color: #ff9933;
    background-color: rgba(255, 153, 51, 0.1);
  }
}