.hero {
    position: relative;
    background-image: url("../../../../public/images/banner4.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 90vh;
    width: 100%;
}

.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) to change the overlay opacity */
}

.hero .container {
    position: relative;
    padding-top: 15%;
    z-index: 1;
}

.hero h1 {
    color: #fff;
    font-size: 60px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 12%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.btn-join, .btn-know-more {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.btn-join {
    background-color: #ff6600;
    color: #fff;
}

.btn-know-more {
    background-color: transparent;
    border: 2px solid #ff6600;
    color: #fff;
    font-weight: bold;
}

.btn-join:hover, .btn-know-more:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 800px) {
    .hero .container {
        padding-top: 10%;
    }
}

@media screen and (max-width: 768px) {
    .hero h1 {
        font-size: 38px;
        margin-top: 20%;
        margin-bottom: 25%;
    }
    .hero p {
        font-size: 20px;
        margin-bottom: 15%;
    }
    .btn-join, .btn-know-more {
        font-size: 12px;
        margin-top: 10%;
    }
}

@media screen and (min-width: 992px) {
    .hero h1 {
        font-size: 60px;
        margin-bottom: 3%;
        font-weight: 500;
    }
    .hero p {
        font-size: 20px;
        margin-bottom: 6%;
    }
}