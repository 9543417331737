.projects .box {
    box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
    border-radius: 6px;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    width: 180px; /* Reduced width for better fit */
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px; /* Fixed height for consistency */
}

.projects img {
    width: 100%;
    height: 150px;
    object-fit: contain; /* Changed to contain to prevent cropping */
    margin-bottom: 10px;
}

.projects h4 {
    font-size: 14px;
    margin-bottom: 5px;
}

.projects label {
    font-size: 12px;
    color: #888;
}

.projects-container {
    width: 100%;
    overflow-x: auto;
    padding: 20px 0;
}

.projects-scroll {
    display: flex;
    gap: 20px;
    width: max-content;
}

/* Styling the scrollbar */
.projects-container::-webkit-scrollbar {
    height: 8px;
}

.projects-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.projects-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.projects-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}